import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import * as geoipResponseJson from './_examples/geoip';
import * as Schemas from './_schemas';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Example = makeShortcode("Example");
const Alert = makeShortcode("Alert");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "toc-headers"
    }}><h2 {...{
        "id": "headers",
        "parentName": "div"
      }}>{`Headers`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Content-Type`}</inlineCode>
        {` for a successful response varies based on the service as
outlined below:`}</p>
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Service`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Content-Type`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`GeoIP2 Country`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`application/vnd.maxmind.com-country+json; charset=UTF-8; version=2.1`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`GeoIP2 City`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`application/vnd.maxmind.com-city+json; charset=UTF-8; version=2.1`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`GeoIP2 Insights`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`application/vnd.maxmind.com-insights+json; charset=UTF-8; version=2.1`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`GeoLite2 Country`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`application/vnd.maxmind.com-country+json; charset=UTF-8; version=2.1`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`GeoLite2 City`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`application/vnd.maxmind.com-city+json; charset=UTF-8; version=2.1`}</inlineCode></td>

          </tr>

        </tbody>

      </table>
      <p {...{
        "parentName": "div"
      }}>{`Errors may be returned with the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Content-Type`}</inlineCode>
        {` set to
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`application/vnd.maxmind.com-error+json; charset=UTF-8; version=2.0`}</inlineCode>
        {`. If this is
the case, then the body of the response contains a JSON document with two keys,
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`code`}</inlineCode>
        {` and `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`error`}</inlineCode>
        {`. See the `}
        <a {...{
          "href": "#errors",
          "parentName": "p"
        }}>{`Errors`}</a>
        {` section for more details.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The response will always include a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Content-Length`}</inlineCode>
        {` header as well.`}</p></div>
    <div {...{
      "id": "toc-bodies"
    }}><h2 {...{
        "id": "bodies",
        "parentName": "div"
      }}>{`Bodies`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Each service returns data as a JSON document. The document that is returned
always consists of an object (aka map or hash). Below are the schema definitions
that describe each service's response body.`}</p>
      <div {...{
        "id": "toc-geoip2-country-body-example",
        "parentName": "div"
      }}><h3 {...{
          "id": "geoip2-country-body-example",
          "parentName": "div"
        }}>{`GeoIP2 Country Body Example`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following is an example of a full response to a GeoIP2 Country web service
request.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`A GeoLite2 Country request follow the same structure, but the data returned will
be less accurate. In addition, GeoLite Country requests will not return the
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`maxmind`}</inlineCode>
          {` object.`}</p>
        <Example language="json" mdxType="Example">
          {JSON.stringify(geoipResponseJson.country, null, 2)}
        </Example></div>
      <div {...{
        "id": "toc-geoip2-city-body-example",
        "parentName": "div"
      }}><h3 {...{
          "id": "geoip2-city-body-example",
          "parentName": "div"
        }}>{`GeoIP2 City Body Example`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following is an example of a full response to a GeoIP2 City Plus web service
request.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`A GeoLite2 City request follow the same structure, but the data returned will be
less accurate. In addition, GeoLite2 City requests will not return the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`domain`}</inlineCode>
          {`,
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`isp`}</inlineCode>
          {`, or `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`organization`}</inlineCode>
          {` values in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`traits`}</inlineCode>
          {` object, and it will not return
the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`maxmind`}</inlineCode>
          {` object.`}</p>
        <Example language="json" mdxType="Example">
          {JSON.stringify(geoipResponseJson.city, null, 2)}
        </Example></div>
      <div {...{
        "id": "toc-geoip2-insights-body-example",
        "parentName": "div"
      }}><h3 {...{
          "id": "geoip2-insights-body-example",
          "parentName": "div"
        }}>{`GeoIP2 Insights Body Example`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following is an example of a full response to a GeoIP2 Insights web service
request.`}</p>
        <Example language="json" mdxType="Example">
          {JSON.stringify(geoipResponseJson.insights, null, 2)}
        </Example></div></div>
    <div {...{
      "id": "toc-errors"
    }}><h2 {...{
        "id": "errors",
        "parentName": "div"
      }}>{`Errors`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`When the server returns an error (`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`4xx`}</inlineCode>
        {` or `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`5xx`}</inlineCode>
        {`), the response may include a
JSON document in the body. This document is a single object with the keys `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`code`}</inlineCode>
        {`
and `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`error`}</inlineCode>
        {`. The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`code`}</inlineCode>
        {` field is a static error code for machine use. The value
of any given code will never change, though codes can be added or removed. The
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`error`}</inlineCode>
        {` field is a human-readable description of the error and may change at any
time.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`Not all errors include a JSON body. An error in content negotiation will not
include a body, nor will many `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`5xx`}</inlineCode>
        {` errors, which typically happen outside of
our web service request handling code. You should check the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Content-Type`}</inlineCode>
        {` type
of an error response before attempting to decode the body as JSON.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`In addition to the errors documented below, client code should also be prepared
to handle any valid HTTP `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`4xx`}</inlineCode>
        {` or `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`5xx`}</inlineCode>
        {` status code.`}</p>
      <table>
        <thead>
          <tr>
            <th>
              <p>{`Code`}</p>
            </th>
            <th>
              <p>{`HTTP Status`}</p>
            </th>
            <th>
              <p>{`Description`}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`IP_ADDRESS_INVALID`}</inlineCode></p>
            </td>
            <td>
              <p>{`400 Bad Request`}</p>
            </td>
            <td>
              <p>{`You have not supplied a valid IPv4 or IPv6 address.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`IP_ADDRESS_REQUIRED`}</inlineCode></p>
            </td>
            <td>
              <p>{`400 Bad Request`}</p>
            </td>
            <td>
              <p>{`You have not supplied an IP address, which is a required field.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`IP_ADDRESS_RESERVED`}</inlineCode></p>
            </td>
            <td>
              <p>{`400 Bad Request`}</p>
            </td>
            <td>
              <p>{`You have supplied an IP address which belongs to a reserved or private
range.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`AUTHORIZATION_INVALID`}</inlineCode></p>
            </td>
            <td>
              <p>{`401 Unauthorized`}</p>
            </td>
            <td>
              <p>{`You have supplied an invalid `}
                <a {...{
                  "href": "https://www.maxmind.com/en/my_license_key",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "p"
                }}>{`MaxMind account ID and/or license key`}</a>
                {`
in the `}
                <a {...{
                  "href": "/minfraud/api-documentation/requests#authorization-and-security",
                  "parentName": "p"
                }}>{`Authorization`}</a>
                {`
header.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`LICENSE_KEY_REQUIRED`}</inlineCode></p>
            </td>
            <td>
              <p>{`401 Unauthorized`}</p>
            </td>
            <td>
              <p>{`You have not supplied a `}
                <a {...{
                  "href": "https://www.maxmind.com/en/my_license_key",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "p"
                }}>{`MaxMind license key`}</a>
                {`
in the `}
                <a {...{
                  "href": "/minfraud/api-documentation/requests#authorization-and-security",
                  "parentName": "p"
                }}>{`Authorization`}</a>
                {`
header.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`ACCOUNT_ID_REQUIRED`}</inlineCode></p>
            </td>
            <td>
              <p>{`401 Unauthorized`}</p>
            </td>
            <td>
              <p>{`You have not supplied a `}
                <a {...{
                  "href": "https://support.maxmind.com/hc/en-us/articles/4412951066779-Find-my-Account-ID",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "p"
                }}>{`MaxMind account ID`}</a>
                {`
in the `}
                <a {...{
                  "href": "/minfraud/api-documentation/requests#authorization-and-security",
                  "parentName": "p"
                }}>{`Authorization`}</a>
                {`
header.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`INSUFFICIENT_FUNDS`}</inlineCode></p>
            </td>
            <td>
              <p>{`402 Payment Required`}</p>
            </td>
            <td>
              <p>{`The license key you have provided does not have sufficient funds to use
this service. Please `}
                <a {...{
                  "href": "https://www.maxmind.com/en/purchase-minfraud-services",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "p"
                }}>{`purchase more service credits`}</a>
                {`.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`PERMISSION_REQUIRED`}</inlineCode></p>
            </td>
            <td>
              <p>{`402 Payment Required`}</p>
            </td>
            <td>
              <p>{`You do not have permission to use the service. Please contact
`}
                <a {...{
                  "href": "mailto:support@maxmind.com",
                  "parentName": "p"
                }}>{`support@maxmind.com`}</a>
                {` for more information.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{`(none)`}</p>
            </td>
            <td>
              <p>{`403 Forbidden`}</p>
            </td>
            <td>
              <p>{`This status is returned when the request body is larger than 20,000
bytes.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`IP_ADDRESS_NOT_FOUND`}</inlineCode></p>
            </td>
            <td>
              <p>{`404 Not Found`}</p>
            </td>
            <td>
              <p>{`The supplied IP address is not in the database.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{`(none)`}</p>
            </td>
            <td>
              <p>{`415 Unsupported Media Type`}</p>
            </td>
            <td>
              <p>{`Your request included a `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`Content-Type`}</inlineCode>
                {` header that is not supported. For
`}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`GET`}</inlineCode>
                {` requests, this means the web service cannot return content of that
type. For `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`PUT`}</inlineCode>
                {` and `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`POST`}</inlineCode>
                {` queries, this means the web service cannot
parse a request body of that type.`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{`(none)`}</p>
            </td>
            <td>
              <p>{`503 Service Not Available`}</p>
            </td>
            <td>
              <p>{`There is a problem with the web service server. You can try this request
again later.`}</p>
            </td>
          </tr>
        </tbody>
      </table></div>
    <div {...{
      "id": "toc-object-reference"
    }}><h2 {...{
        "id": "object-reference",
        "parentName": "div"
      }}>{`Object Reference`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Each schema definition contains a description of an object, along with a list of
properties that belong to the object. The following information is listed for
each object property:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}>{`name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`type (`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`array<type>`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`boolean`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`number`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`integer`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`object`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`string`}</inlineCode>
          {`)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`description`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`example`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`formatting`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`constraints`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`supported services (`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Country`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`City`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Insights`}</inlineCode>
          {`)`}</li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`Additionally, for `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`object`}</inlineCode>
        {` properties, a link is provided to view a schema
definition that further describes that specific object.`}</p>
      <Schemas.Response />
      <Schemas.ResponseCity />
      <Schemas.ResponseContinent />
      <Schemas.ResponseCountry />
      <Schemas.ResponseLocation />
      <Schemas.ResponsePostal />
      <Schemas.ResponseRegisteredCountry />
      <Schemas.ResponseRepresentedCountry />
      <Schemas.ResponseSubdivisions />
      <Schemas.ResponseSubdivision />
      <Schemas.ResponseTraits />
      <Schemas.ResponseMaxMind /></div>
    <div {...{
      "id": "toc-miscellaneous-notes"
    }}><h2 {...{
        "id": "miscellaneous-notes",
        "parentName": "div"
      }}>{`Miscellaneous Notes`}</h2>
      <div {...{
        "id": "toc-languages",
        "parentName": "div"
      }}><h3 {...{
          "id": "languages",
          "parentName": "div"
        }}>{`Languages`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Many of the objects listed above include a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`names`}</inlineCode>
          {` key. The value of that key is
in turn an object which maps locale codes to a name in the appropriate language
and script.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Currently, this web service may return the following locale codes:`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Code`}</p>
              </th>
              <th>
                <p>{`Language`}</p>
              </th>
              <th>
                <p>{`Notes`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{`de`}</p>
              </td>
              <td>
                <p>{`German`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`en`}</p>
              </td>
              <td>
                <p>{`English`}</p>
              </td>
              <td>
                <p>{`English names may still include accented characters if that is the
accepted spelling in English. In other words, English does not mean
ASCII.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`es`}</p>
              </td>
              <td>
                <p>{`Spanish`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`fr`}</p>
              </td>
              <td>
                <p>{`French`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`ja`}</p>
              </td>
              <td>
                <p>{`Japanese`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`pt-BR`}</p>
              </td>
              <td>
                <p>{`Brazilian Portuguese`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`ru`}</p>
              </td>
              <td>
                <p>{`Russian`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
            <tr>
              <td>
                <p>{`zh-CN`}</p>
              </td>
              <td>
                <p>{`Chinese (Simplified)`}</p>
              </td>
              <td>
                {' '}
              </td>
            </tr>
          </tbody>
        </table>
        <p {...{
          "parentName": "div"
        }}>{`If an object has any name data, then `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`en`}</inlineCode>
          {` will be one of the keys in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`names`}</inlineCode>
          {`
object. No other language is guaranteed. However, it is possible that we might
not have any name data at all for a given object.`}</p></div>
      <div {...{
        "id": "toc-returned-values-as-database-map-dict-or-hash-keys",
        "parentName": "div"
      }}><h3 {...{
          "id": "returned-values-as-database-map-dict-or-hash-keys",
          "parentName": "div"
        }}>{`Returned Values as Database, Map, Dict, or Hash Keys`}</h3>
        <Alert type="warning" mdxType="Alert">
          <p>{`We strongly discourage you from using a value from any `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`names`}</inlineCode>
            {` field as a key
in a database or map/dict/hash data structure.`}</p>
        </Alert>
        <p {...{
          "parentName": "div"
        }}>{`These names may change between releases. Instead we recommend using one of the
following:`}</p>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Data object`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Recommended key`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`city`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`geoname_id`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`continent`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`code`}</inlineCode>
                {` or `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`geoname_id`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`country`}</inlineCode>
                {`, `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`registered_country`}</inlineCode>
                {`, and `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`represented_country`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`iso_code`}</inlineCode>
                {` or `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`geoname_id`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`postal`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`code`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`subdivisions`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`iso_code`}</inlineCode>
                {` or `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`geoname_id`}</inlineCode></td>

            </tr>

          </tbody>

        </table></div></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      